import { useState } from "react";
import { ContactFormType } from "../../models/enums/contact-form-type";
import { ContactForm } from "../contact-form/ContactForm";
import { Modal } from "../modal/Modal";
import styles from "./EnterprisePlanDesktop.module.scss";

const featuresLeft: string[] = [
    'Bespoke Package',
    'Roll over unused monthly credits',
    'Multiple users'];

const featuresRight: string[] = [
    'Cancel at any time',
    'Premium Support*'];

export function EnterprisePlanDesktop(props: { isCurrent: boolean }) {

    const activeClassNameOrEmpty: string = props.isCurrent ? styles["enterprise-plan-item--active"] : '';
    const [showModal, setShowModal] = useState(false);

    const contactSales = async function () {
        setShowModal(true);
    }

    const closeModal = function () {
        setShowModal(false);
    }

    return <div className={styles.desktop}>
        <div className="row">
            <div className="col-12 flex align-center margin--top-extra-large">
                <div className={`${styles["enterprise-plan-item"]} ${activeClassNameOrEmpty}`}>
                    <div className={styles["enterprise-plan-item__icon-wrapper"]}>
                        <div className={styles["enterprise-plan-item__icon"]}>
                            <span className="material-icons">apartment</span>
                        </div>
                        <h3>Enterprise</h3>
                    </div>
                    <div className={styles["enterprise-plan-item__features"]}>
                        <div className="row">
                            <div className="col-7">
                                <ul>
                                    {featuresLeft.map((feature: string) => <li key={feature}>
                                        <p>{!!feature ? <span className="material-icons">check</span> : <span>&nbsp;</span>}{feature}</p>
                                    </li>)}
                                </ul>
                            </div>
                            <div className="col-5">
                                <ul>
                                    {featuresRight.map((feature: string) => <li key={feature}>
                                        <p>{!!feature ? <span className="material-icons">check</span> : <span>&nbsp;</span>}{feature}</p>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button onClick={contactSales}
                            className={`${styles["enterprise-plan-item__button"]} btn`}>
                            <span>CONTACT SALES</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        {showModal && <Modal width={800} height={520} hasCloseButton={true} close={closeModal}>
            <div className={styles.contactFormWrapper}>
                <h3>Contact Sales for Enterprise Package</h3>
                <ContactForm onSuccess={closeModal} onFail={closeModal}
                    formType={ContactFormType.pricing} center={true}></ContactForm>
            </div>
        </Modal>}
    </div>
}