import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isValidEmail } from "../../../helpers/common-helper";
import { useInput } from "../../../hooks/use-input";
import { IEnterpriseAccountSettings } from "../../models/enterprise-account-settings.model";
import { RootState } from "../../../store";
import { EnterpriseSearch } from "../enterprise-search/EnterpriseSearch";
import styles from "./EnterpriseEditor.module.scss";
import { IEnterprise } from "../../models/enterprise.model";
import { IEnterpriseUser } from "../../models/enterprise-user.model";

export function EnterpriseEditor(props: {
    enterprise: IEnterprise | null,
    settings: IEnterpriseAccountSettings,
    users: IEnterpriseUser[],
    backHandler: () => void,
    saveSettingsHandler: (settings: IEnterpriseAccountSettings) => void,
    deleteUser: (user: IEnterpriseUser) => void,
    createUser: (user: IEnterpriseUser) => void
}) {
    const days: number[] = Array.from(Array(29).keys()).slice(1);
    const [keyword, setKeyword] = useState<string>('');
    const processing: boolean = useSelector<RootState, boolean>(state => state.enterprise.processing);
    const [filteredList, setFilteredList] = useState<IEnterpriseUser[]>([]);

    const renewalDay = useInput(props.settings.renewalDay.toString(), input => !!input);
    const monthlyLimit = useInput(props.settings.titleDocumentMonthlyUploadsLimit.toString(), input => !!input);
    const limit = useInput(props.settings.titleDocumentUploadsLimit.toString(), input => !!input);

    useEffect(() => {
        setFilteredList(props.users.filter(
            (item: IEnterpriseUser) => !keyword ? item : item.email.toLowerCase().indexOf(keyword.toLowerCase()) > -1));
    }, [keyword, props.users]);

    const handleDeleteUser = function (user: IEnterpriseUser) {
        const { confirm } = window as any;
        if (confirm('Are you sure that you would like to delete this user?')) {
            props.deleteUser({ ...user, accountId: props.enterprise?.id });
        }
    }

    const handleCreateUser = function (email: string) {
        if (!email) {
            return;
        }
        setKeyword('');
        props.createUser({ email, accountId: props.enterprise?.id });
    }

    const handleSave = function () {
        const { confirm } = window as any;
        if (confirm('Are you sure that you would like to adjust the settings for this enterprise customer?')) {
            props.saveSettingsHandler({
                titleDocumentMonthlyUploadsLimit: +monthlyLimit.value,
                renewalDay: +renewalDay.value,
                titleDocumentUploadsLimit: +limit.value,
                accountId: props.enterprise?.id
            });
        }
    }

    const canSave = function (): boolean | undefined {
        return !processing && renewalDay.isValid && monthlyLimit.isValid && limit.isValid;
    }

    return <Fragment>
        <div className="row">
            <div className="col-12">
                <div onClick={props.backHandler} className={styles['back-wrapper']}>
                    <span className="material-icons">arrow_back</span>&nbsp;{props.enterprise?.name || 'Back'}
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className={styles.box}>
                    <Fragment>
                        <div className={styles.section}>
                            <label>Monthly upload limit:</label>
                            <input type="number" value={monthlyLimit.value} onChange={monthlyLimit.valueChangeHandler} />
                        </div>
                        <div className={styles.section}>
                            <label>Day of renewal:</label>
                            <select value={renewalDay.value} onChange={renewalDay.valueChangeHandler}>
                                {days.map(value => <option key={value} value={value.toString()}>{value}</option>)}
                            </select>
                        </div>
                        <div className={styles.section}>
                            <label>Current balance:</label>
                            <input type="number" min={0} max={10000} value={limit.value} onChange={limit.valueChangeHandler} />
                        </div>
                        <div className={`${styles.section} flex align-right`}>
                            <button
                                onClick={handleSave}
                                disabled={!canSave()}
                                className="btn btn--secondary padding--left-large padding--right-large">
                                <span>SAVE</span>
                            </button>
                        </div>
                    </Fragment>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className={styles.box}>
                    <EnterpriseSearch
                        validator={isValidEmail}
                        searchHandler={(keyword: string) => setKeyword(keyword)}
                        createHandler={(email: string) => handleCreateUser(email)}></EnterpriseSearch>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="small-header" scope="col">#</th>
                                <th scope="col">Email</th>
                                <th className="small-header" scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((user: IEnterpriseUser, index: number) => <tr key={user.id}>
                                <td>{index + 1}</td>
                                <td>{user.email}</td>
                                <td onClick={() => handleDeleteUser(user)}>Delete</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Fragment>
}