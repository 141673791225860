import { TextField } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isValidEmail } from "../../helpers/common-helper";
import { useHttp } from "../../hooks/use-http";
import { useInput } from "../../hooks/use-input";
import { ContactFormType } from "../../models/enums/contact-form-type";
import { mainActions } from './../../store/main-slice';
import styles from "./ContactForm.module.scss";

let submitted: boolean = false;

export function ContactForm(props: {
    formType: ContactFormType,
    center?: boolean,
    onSuccess?: () => void,
    onFail?: () => void
}) {
    const [request, status] = useHttp<string>(false);
    const [checked, setChecked] = useState(false);
    const [canShow, setCanShow] = useState(true);

    const dispatch = useDispatch();
    const name = useInput('', input => !!input);
    const email = useInput('', input => isValidEmail(input));
    const message = useInput('', input => !!input);
    const buttonWrapperClassName: string = props.center
        ? `${styles['button-wrapper--center']} ${styles['button-wrapper']}`
        : styles['button-wrapper'];

    const isValidForm = function (): boolean {
        return name.isValid && email.isValid && message.isValid && checked;
    }

    const captchaCallbackHandler = useCallback((response: string) => {
        setChecked(!!response);
    }, [setChecked]);

    const submitForm = async function () {
        const { grecaptcha } = window as any;
        await request('/contact', 'POST', JSON.stringify({
            name: name.value,
            email: email.value,
            text: message.value,
            type: props.formType,
            token: grecaptcha.getResponse()
        }), null);
    }

    const setupCaptcha = useCallback(function() {
        const { REACT_APP_CAPTCHA_KEY } = process.env;
        const { grecaptcha, } = window as any;
        if (!!grecaptcha) {
            grecaptcha.ready(function () {
                grecaptcha.render(styles.recaptcha, {
                    "sitekey": REACT_APP_CAPTCHA_KEY,
                    "callback": captchaCallbackHandler
                });
            });
            submitted = false;
        }
        else {
            setTimeout(setupCaptcha, 3000);
        }
    }, [captchaCallbackHandler]);

    useEffect(() => {
        if (submitted) {
            return;
        }
        if (status === 200) {
            submitted = true;
            setCanShow(false);
            dispatch(mainActions.setMessage(
                {
                    text: 'Your contact form has been successfully submitted. We will be in touch with you shortly',
                    isError: false
                }));
            if (!!props.onSuccess) {
                props.onSuccess();
            }
        }
        if (status >= 400) {
            submitted = true;
            if (!!props.onFail) {
                props.onFail();
            }
        }
    }, [status, dispatch, props]);

    useEffect(() => {
        setupCaptcha();
    }, [captchaCallbackHandler, setupCaptcha]);

    return <div className={styles.contact}>
        {canShow && <div className="flex-column align-center-middle">
            <div className="margin--bottom-large">
                <TextField className={`${styles.name} ${styles.field}`} type="text" label="Full Name"
                    onChange={name.valueChangeHandler}
                    value={name.value}
                    onBlur={name.blurHandler} />
                <TextField className={`${styles.email} ${styles.field}`} type="email" label="Email"
                    onChange={email.valueChangeHandler}
                    value={email.value}
                    onBlur={email.blurHandler} />
            </div>
            <div>
                <TextField placeholder="Message"
                    onChange={message.valueChangeHandler}
                    value={message.value}
                    fullWidth={true}
                    minRows={5}
                    maxRows={5}
                    multiline={true}
                    onBlur={message.blurHandler}></TextField>
            </div>
            <div className={buttonWrapperClassName}>
                <div className="margin--top-large" id={styles.recaptcha}></div>
                <button disabled={!isValidForm()} onClick={submitForm}
                    className="btn btn--secondary margin--top-large margin--bottom-large padding--left-large padding--right-large"
                    type="button">
                    <span>SEND REQUEST</span>
                </button>
            </div>
        </div>}
    </div>
}
