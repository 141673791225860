import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHttp } from "../../hooks/use-http";
import { mainActions } from "../../store/main-slice";
import { Modal } from "../modal/Modal";
import styles from "./CancelSubscription.module.scss";

export function CancelSubscription() {

    const [showModal, setShowModal] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [request, status] = useHttp<string>(false);
    const dispatch = useDispatch();
    const cancel = async () => await request('/payments/cancel', 'POST', {}, null);

    useEffect(() => {
        if (status === 0) {
            return;
        }
        setShowModal(false);

        let messages: { [status: number]: string } = {
            200: 'Your subscription has been cancelled'
        };
        const isSuccess: boolean = status === 200;
        if (isSuccess) {
            setShowButton(false);
        }
        if (!!messages[status]) {
            dispatch(mainActions.setMessage({ text: messages[status], isError: !isSuccess }));
        }
    }, [status, dispatch, setShowButton]);

    return <Fragment>
        {showButton && <button onClick={() => setShowModal(true)}
            className="text-link">CANCEL SUBSCRIPTION
            <span className="material-icons">keyboard_arrow_right</span>
        </button>}
        {showModal && <Modal>
            <p className={styles.confirm}>Are you sure that you would like to cancel your subscription?</p>
            <p className={styles.notes}>Note that if you cancel your subscription you will lose all unused credits</p>
            <div className="flex align-right">
                <button className="text-link" onClick={() => setShowModal(false)}>
                    <span>Keep Subscription</span>
                </button>
                <button className="btn btn--main" onClick={async () => await cancel()}>
                    <span>Cancel Subscription</span>
                </button>
            </div>
        </Modal>}
    </Fragment>
}