import { useCallback, useEffect } from "react";
import styles from "./PasswordPolicy.module.scss";

export function PasswordPolicy(props: { password: string, onValidationResult: (result: boolean) => void }) {

    const hasEnoughLength = useCallback(() => props.password.length >= 8, [props.password]);
    const hasSpecialSymbol = useCallback(() => !!props.password.match(/[^a-zA-Z0-9]+/), [props.password]);
    const hasNumber = useCallback(() => /\d/.test(props.password), [props.password]);
    const hasCapitalLetter = useCallback(() => !!props.password.split('')
        .find((c: string) => c.toUpperCase() === c && c.match(/[a-z]/i)), [props.password]);

    useEffect(() => {
        const isValid: boolean = hasEnoughLength() && hasNumber() && hasSpecialSymbol() && hasCapitalLetter();
        props.onValidationResult(isValid);
    }, [props, hasEnoughLength, hasSpecialSymbol, hasNumber, hasCapitalLetter]);

    return <div>
        {!!props.password && <ul className={styles.policy}>
            <li className={hasEnoughLength() ? styles.valid : styles.invalid}>At least 8 characters</li>
            <li className={hasCapitalLetter() ? styles.valid : styles.invalid}>At least one capital letter</li>
            <li className={hasSpecialSymbol() ? styles.valid : styles.invalid}>At least one symbol</li>
            <li className={hasNumber() ? styles.valid : styles.invalid}>At least one number</li>
        </ul>}
    </div>
}