import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import warningImage from '../../assets/icn/warning.svg';
import { IMessage } from '../../models/message.model';
import { mainActions } from '../../store/main-slice';

export function Message(props: { message: IMessage | null }) {

    const dispatch = useDispatch();
    useEffect(() => {
        if (!!props.message) {
            setTimeout(() => {
                dispatch(mainActions.setMessage(null));
            }, 5000);
        }
    }, [dispatch, props.message]);

    return <Fragment>
        {!!props.message && !!props.message.text &&
            <div className="popup">
                {props.message.isError && <img src={warningImage} alt="" />}
                <p>{props.message.text}</p>
            </div>}
    </Fragment>
}