import { createSlice } from "@reduxjs/toolkit";
import { IMessage } from "../models/message.model";

type IState = {
    loggedIn: boolean | null;
    message: IMessage | null;
}

const initialState: IState = {
    loggedIn: null,
    message: null
};

const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setLoggedIn(state, action: { type: string, payload: boolean }) {
            state.loggedIn = action.payload;
            state.message = action.payload ? null : state.message;
        },
        setMessage(state, action: { type: string, payload: IMessage | null }) {
            state.message = action.payload;
        }
    },
});

export const mainActions = mainSlice.actions;
export default mainSlice.reducer;