import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enterpriseActions } from "../../../store/enterprise-slice";
import { EnterpriseEditor } from "../../components/enterprise-editor/EnterpriseEditor";
import { IEnterpriseAccountSettings } from "../../models/enterprise-account-settings.model";
import { AppDispatch, RootState } from "../../../store";
import { EnterpriseList } from "../../components/enterprise-list/EnterpriseList";
import { EnterpriseSearch } from "../../components/enterprise-search/EnterpriseSearch";
import { IEnterprise } from "../../models/enterprise.model";
import * as thunkActions from "../../../store/thunks/enterprise-accounts.thunk";
import styles from "./Enterprise.module.scss";
import { IEnterpriseUser } from "../../models/enterprise-user.model";

export function Enterprise() {
    const list: IEnterprise[] = useSelector<RootState, IEnterprise[]>(state => state.enterprise.list);
    const users: IEnterpriseUser[] = useSelector<RootState, IEnterpriseUser[]>(state => state.enterprise.users);
    const processing: boolean = useSelector<RootState, boolean>(state => state.enterprise.processing);
    const settings: IEnterpriseAccountSettings | null = useSelector<RootState, IEnterpriseAccountSettings | null>(
        state => state.enterprise.settings);
    const errorMessage: string | undefined = useSelector<RootState, string | undefined>(state => state.enterprise.error);
    const [filteredList, setFilteredList] = useState<IEnterprise[]>([]);
    const [keyword, setKeyword] = useState<string>('');
    const [current, setCurrent] = useState<IEnterprise | null>(null);
    const thunkDispatch: any = useDispatch<AppDispatch>();
    const dispatch: any = useDispatch();


    useEffect(() => {
        thunkDispatch(thunkActions.fetchEnterpriseAccounts());
    }, [thunkDispatch]);

    useEffect(() => {
        setFilteredList(list.filter(
            (item: IEnterprise) => !keyword ? item : (item.name || '').toLowerCase().indexOf(keyword.toLowerCase()) > -1));
    }, [keyword, list, thunkDispatch]);

    const handleDelete = function (enterprise: IEnterprise) {
        const { confirm } = window as any;
        if (confirm('Are you sure that you would like to delete this enterprise account?')) {
            thunkDispatch(thunkActions.deleteEnterpriseAccount(enterprise));
        }
    }

    const handleDownload = function (enterprise: IEnterprise) {
        thunkDispatch(thunkActions.downloadUploadDocumentHistory(enterprise));
    }

    const handleCreate = function (name: string) {
        if (!name) {
            return;
        }
        thunkDispatch(thunkActions.createEnterpriseAccount({ name }));
        setKeyword('');
    }

    const handleSelect = function (enterprise: IEnterprise) {
        if (!enterprise.name) {
            return;
        }
        setCurrent(enterprise);
        thunkDispatch(thunkActions.fetchEnterpriseAccountSettings(enterprise));
        thunkDispatch(thunkActions.fetchEnterpriseUsers(enterprise));
    }

    const handleSaveSettings = function(settings: IEnterpriseAccountSettings) {
        thunkDispatch(thunkActions.saveEnterpriseAccountSettings(settings));
    }

    const handleBack = function() {
        setCurrent(null);
        dispatch(enterpriseActions.reset());
    }

    const handleCreateUser = function(user:IEnterpriseUser) {
        thunkDispatch(thunkActions.createEnterpriseUser(user))
    }

    const handleDeleteUser = function(user:IEnterpriseUser) {
        thunkDispatch(thunkActions.deleteEnterpriseUser(user))
    }

    return <Fragment>
        <div className={styles.container}>
            <div className="row">
                {!errorMessage && <h3 className={styles.title}>{processing ? 'Processing...' : 'Enterprise management'}</h3>}
                {!!errorMessage && <h3 className={styles.error}>{errorMessage}</h3>}
            </div>
            {!settings && <EnterpriseSearch
                searchHandler={(keyword: string) => setKeyword(keyword)}
                createHandler={(name: string) => handleCreate(name)}></EnterpriseSearch>}
            {!settings && <EnterpriseList
                list={filteredList}
                selectHandler={handleSelect}
                deleteHandler={(e: IEnterprise) => handleDelete(e)}
                downloadHandler={(e: IEnterprise) => handleDownload(e)}></EnterpriseList>}
            {!!settings &&
                <EnterpriseEditor 
                users={users}
                enterprise={current} 
                settings={settings} 
                saveSettingsHandler={handleSaveSettings}
                backHandler={handleBack}
                deleteUser={handleDeleteUser}
                createUser={handleCreateUser}></EnterpriseEditor>}
        </div>
    </Fragment>
}