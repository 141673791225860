import { Fragment, useState } from "react"
import { Modal } from "../modal/Modal";

export function WatchVideoButton(props: { isMobile: boolean }) {
    const [showVideo, setShowVideo] = useState(false);
    return <Fragment>
        <button onClick={() => setShowVideo(true)}
            className={props.isMobile ? 'btn btn--watch-video btn--mobile' : 'btn btn--watch-video'}>
            <span className="material-icons margin--right-tiny">play_arrow</span>
            <span>WATCH VIDEO</span>
        </button>
        {showVideo && <Modal width={533} height={315} close={() => setShowVideo(false)}>
            <iframe title="title extraction" width="100%" height="100%" src="https://www.youtube.com/embed/s-lK0OAjs1I"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}></iframe>
        </Modal>
        }
    </Fragment >
}