import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField } from '@material-ui/core';
import { PasswordPolicy } from "../../components/password-policy/PasswordPolicy";
import { isConfirmPasswordValid } from "../../helpers/common-helper";
import { useHttp } from "../../hooks/use-http";
import { mainActions } from "../../store/main-slice";
import styles from "./Register.module.scss";

export function Register() {
    const [email, setEmail] = useState<string | null>(null)
    const [token, setToken] = useState<string | null>(null);
    const [requestPath, setRequestUrl] = useState('reset-password');
    const [buttonTitle, setButtonTitle] = useState('Change Password');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hasValidPolicy, setHasValidPolicy] = useState<boolean>(false);

    const navigate = useNavigate();
    const [request, status, loading] = useHttp<string>(false);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token: string | null = query.get('token');
        setEmail((location.state as string) || query.get('email'));
        setToken(token);
        if (!token) {
            setRequestUrl('register');
            setButtonTitle('Continue');
        }
    }, [location]);

    useEffect(() => {
        if (status === 200) {
            const text: string | null = !token ? 'Please check your email to verify your email address' : 'Password changed';
            dispatch(mainActions.setMessage({ text: text, isError: false }));
            navigate('/');
        }
    }, [token, status, loading, dispatch, navigate]);

    const canSubmit = function () {
        return hasValidPolicy && !!confirmPassword && isConfirmPasswordValid(password, confirmPassword);
    }

    const submit = async function () {
        const requestModel = {
            email: email,
            password: password,
            newPassword: password,
            confirmPassword: confirmPassword,
            token: token
        };
        await request(`/accounts/${requestPath}`, 'POST', JSON.stringify(requestModel), null);
    }
    return <div className={styles.register}>
        <div className={styles.title}>
            <h3>Create New Password</h3>
        </div>
        <div className={styles.content}>
            <TextField type="password" className="margin--bottom-medium"
                label="New password" onChange={(e: any) => setPassword(e.target.value)} />
            <PasswordPolicy password={password}
                onValidationResult={(result: boolean) => setHasValidPolicy(result)}></PasswordPolicy>
            <TextField type="password" className="margin--bottom-medium"
                label="Verify password" onChange={(e: any) => setConfirmPassword(e.target.value)} />
            {!isConfirmPasswordValid(password, confirmPassword)
                && <span className={styles.error}>The passwords do not match</span>}
            <button disabled={!canSubmit()} onClick={submit}
                className="btn btn--main margin--top-medium" type="button">
                <span>{buttonTitle}</span>
            </button>
        </div>
    </div>
}