import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEnterpriseAccountSettings } from "../superadmin/models/enterprise-account-settings.model";
import { IEnterpriseUser } from "../superadmin/models/enterprise-user.model";
import { IEnterprise } from "../superadmin/models/enterprise.model";
import * as thunkActions from "./thunks/enterprise-accounts.thunk";

type IState = {
    list: IEnterprise[];
    settings: IEnterpriseAccountSettings | null;
    users: IEnterpriseUser[];
    processing: boolean;
    error: string | undefined;
}

const initialState: IState = {
    list: [],
    settings: null,
    processing: false,
    error: undefined,
    users: []
};

const enterpriseSlice = createSlice({
    name: 'enterprise',
    initialState,
    reducers: {
        reset(state) {
            state.settings = null;
            state.users = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(thunkActions.fetchEnterpriseAccounts.pending, (state) => {
            state.processing = true;
            state.error = undefined;
        })
        builder.addCase(thunkActions.fetchEnterpriseAccounts.rejected, (state, action) => {
            state.processing = false;
            state.list = [];
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.fetchEnterpriseAccounts.fulfilled, (state, action: PayloadAction<IEnterprise[]>) => {
            state.processing = false;
            state.list = action.payload || [];
            state.error = undefined;
        })
        builder.addCase(thunkActions.deleteEnterpriseAccount.rejected, (state, action) => {
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.deleteEnterpriseAccount.fulfilled, (state, action) => {
            state.error = undefined;
            state.list = state.list.filter((item: IEnterprise) => item.id !== action.payload)
        })
        builder.addCase(thunkActions.createEnterpriseAccount.rejected, (state, action) => {
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.createEnterpriseAccount.fulfilled, (state, action: PayloadAction<IEnterprise>) => {
            state.error = undefined;
            state.list = [action.payload, ...state.list];
        })

        builder.addCase(thunkActions.fetchEnterpriseAccountSettings.pending, (state) => {
            state.processing = true;
            state.error = undefined;
        })
        builder.addCase(thunkActions.fetchEnterpriseAccountSettings.rejected, (state, action) => {
            state.processing = false;
            state.settings = null;
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.fetchEnterpriseAccountSettings.fulfilled, (state, action: PayloadAction<IEnterpriseAccountSettings>) => {
            state.processing = false;
            state.settings = action.payload || { titleDocumentUploadsLimit: 0, titleDocumentMonthlyUploadsLimit: 0, renewalDay: 1 };
            state.error = undefined;
        })

        builder.addCase(thunkActions.saveEnterpriseAccountSettings.pending, (state) => {
            state.processing = true;
            state.error = undefined;
        })
        builder.addCase(thunkActions.saveEnterpriseAccountSettings.rejected, (state, action) => {
            state.processing = false;
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.saveEnterpriseAccountSettings.fulfilled, (state) => {
            state.processing = false;
            state.error = undefined;
        })

        builder.addCase(thunkActions.fetchEnterpriseUsers.pending, (state) => {
            state.processing = true;
            state.error = undefined;
        })
        builder.addCase(thunkActions.fetchEnterpriseUsers.rejected, (state, action) => {
            state.processing = false;
            state.users = [];
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.fetchEnterpriseUsers.fulfilled, (state, action: PayloadAction<IEnterpriseUser[]>) => {
            state.processing = false;
            state.users = action.payload || [];
            state.error = undefined;
        })
        builder.addCase(thunkActions.deleteEnterpriseUser.rejected, (state, action) => {
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.deleteEnterpriseUser.fulfilled, (state, action) => {
            state.error = undefined;
            state.users = state.users.filter((item: IEnterpriseUser) => item.id !== action.payload)
        })
        builder.addCase(thunkActions.createEnterpriseUser.rejected, (state, action) => {
            state.error = action.error.message;
        })
        builder.addCase(thunkActions.createEnterpriseUser.fulfilled, (state, action: PayloadAction<IEnterpriseUser>) => {
            state.error = undefined;
            state.users = [action.payload, ...state.users];
        })
    }
});

export const enterpriseActions = enterpriseSlice.actions;
export default enterpriseSlice.reducer;