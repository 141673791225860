import { IAccountSettingCredits } from "../../models/account-settings.model";
import styles from "./Credits.module.scss";

export function Credits(props: { credit: IAccountSettingCredits }) {

    const wrapperClassName: string = `content-wrap ${styles.wrapper}`;

    const formattedDate = function (dateString: string): string {
        return dateString.slice(0, dateString.indexOf('T'))
            .split('-').map((part: string) => part.slice(-2))
            .reverse().join('/')
    }

    return <div className={wrapperClassName}>
        <div className={styles.credits}>
            <div className={styles.box}>
                <p className={styles.label}>Remaining Credits</p>
                <span className={styles.value}>{props.credit.titleDocumentLimit}</span>
            </div>
            <div className={styles.box}>
                <p className={styles.label}>Renewal Date</p>
                <span className={styles.value}>{formattedDate(props.credit.renewalDate)}</span>
            </div>
        </div>
    </div>
}