import { NavLink } from "react-router-dom";
import bannerImage from '../../assets/about-banner.png';
import styles from "./About.module.scss";
export function About() {
    return <div>
        <div className={styles.banner}>
            <div className={styles["banner-header"]}>
                <NavLink to={"/"}><span className={styles.bold}>Home</span></NavLink>
                <span className={`${styles.divider} material-icons`}>chevron_right</span>
                <span>About</span>
            </div>
            <div className={styles["banner-content"]}>
                <h2>About</h2>
                <img src={bannerImage} alt="" />
            </div>
        </div>
        <p className={styles.content}>
            Click Reporting was founded in 2021 by Benjamin Zatman and Michael Schurder.
            Benjamin is a solicitor with over 17 years of experience in dealing with commercial
            and residential property transactions.  Michael is an  experienced, creative VP
            Product with a proven track record, having led the product team at Graduway from
            concept through to a $60m exit.
            Click Reporting are creating automation tools for UK property lawyers that assist
            with the time-consuming and tedious tasks that are inherent to this area of law.
            Our first tool, already hugely popular, was launched in Q3 2022 and it extracts
            information from Title Registers and presents the information in a Title Report
            format. This enables the lawyer to spend less time on administrative tasks
            increasing profitability in real estate transactions.
        </p>
    </div>
}