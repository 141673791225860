import { IResource } from "./models/resource.model";

const resources: IResource[] = [
    {
        id: 'tCGFnLw2E2g',
        title: 'Title Extraction Demo',
        description: 'Watch the Click Reporting platform in action. See how the data is extracted from The Title Register in seconds and inserted neatly into a Report.',
        url: 'https://www.youtube.com/embed/tCGFnLw2E2g'
    },
    {
        id: 'sePyys2WlOM',
        title: 'Case Study',
        description: 'A side by side comparison of manual data extraction from a Title Register vs using Click Reporting. See for yourself how much time you could save.',
        url: 'https://www.youtube.com/embed/sePyys2WlOM'
    },
    {
        id: 'Z6i0MXv8ceI',
        title: 'Webinar',
        description: 'Watch a recording of a webinar in which the Co-Founders of Click Reporting, Benjamin Zatman and Michael Schurder discuss how easily automation can simplify the process of generating Title Reports.',
        url: 'https://www.youtube.com/embed/Z6i0MXv8ceI'
    }
]

export default resources;