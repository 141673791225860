export const isValidEmail = function (input: string | null): boolean {
    if (!input) {
        return false;
    }
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return expression.test(input);
}
export const isConfirmPasswordValid = function(password: string, confirmPassword: string): boolean {
    if (!password || !confirmPassword) {
        return true;
    }
    return !!password && !!confirmPassword && password.length >=8 && password === confirmPassword;
}