import { Fragment, useCallback } from "react";
import { useEffect, useState } from "react";
import { useHttp } from "../../hooks/use-http";
import { IAccountSettingCredits } from "../../models/account-settings.model";
import pdfImage from '../../assets/icn/pdf.png';
import automateImage from '../../assets/icn/automate.png';
import aiImage from '../../assets/icn/ai2.png';
import wordImage from '../../assets/icn/word.png';
import { FileUploader } from "../../components/file-uploader/FileUploader";
import { Credits } from "../../components/credits/Credits";

export function Home() {
    const [request] = useHttp<IAccountSettingCredits>(false);
    const [credit, setCredit] = useState<IAccountSettingCredits | null>(null);

    const getCreditsInfo = useCallback(async () => {
        const setting: IAccountSettingCredits | null = await request(`/account-settings/credits`);
        setCredit(setting);
    }, [request]);

    useEffect(() => { getCreditsInfo() }, [getCreditsInfo]);

    return <Fragment>
        {credit && <Credits credit={credit}></Credits>}
        <section className="section section--upload-file">
            <div className="container-fluid">
                <div className="content-wrap">
                    <h3>Upload Your PDF Title Register Here</h3>
                    <p>
                        The system will automatically extract the relevant information and create a summary report in Word format.
                    </p>
                    <span>To help you find the data that had been automatically extracted in the summary,
                        the imported information will be highlighted yellow.
                        Any errors in extraction will be highlighted in red. To remove the highlighting, hold down 'Ctrl' + 'A'.
                        This selects everything in the Word document and you can then
                        click 'no colour' on the highlight tab to remove the highlighting.</span>
                </div>
                <div className="process-steps">
                    <ul>
                        <li>
                            <img src={pdfImage} alt="" />
                            <span>PDF<br />Upload</span>
                        </li>
                        <li>
                            <img src={automateImage} alt="" />
                            <span>Automated<br />Conversion</span>
                        </li>
                        <li>
                            <img src={aiImage} alt="" />
                            <span>AI <br />Extraction</span>
                        </li>
                        <li>
                            <img src={wordImage} alt="" />
                            <span>Word <br />Download</span>
                        </li>
                    </ul>
                </div>
                {!!credit && <FileUploader
                    disable={credit.titleDocumentLimit <= 0}
                    extensions={['pdf']}
                    outputExtension={'docx'}
                    onSuccess={getCreditsInfo}></FileUploader>}
            </div>
        </section>
    </Fragment>
}