import { TextField } from "@material-ui/core";
import { useInput } from "../../../hooks/use-input";

export function EnterpriseSearch(props: {
    createHandler: (name: string) => void,
    searchHandler: (keyword: string) => void,
    validator?: (input: string) => boolean
}) {

    const onSearch = function (e: any) {
        name.valueChangeHandler(e);
        props.searchHandler(e.target.value);
    }

    const onCreate = function() {
        props.createHandler(name.value);
        name.resetHandler();
    }

    const name = useInput('', input => !!props.validator ? props.validator(input) : !!input);

    return <div className="row margin--bottom-large align-middle">
        <TextField style={{ width: 160 }} className="margin--right-medium margin--bottom-medium" type="text" label="Find or Create"
            onChange={onSearch}
            value={name.value}
            onBlur={name.blurHandler} />
        <button onClick={onCreate} disabled={!name.isValid}
            className="btn btn--secondary padding--left-large padding--right-large">
            <span>CREATE</span>
        </button>
    </div>
}