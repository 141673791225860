import { Fragment } from "react"
import { IEnterprise } from "../../models/enterprise.model"

export function EnterpriseList(props: {
    list: IEnterprise[],
    selectHandler: (enterprise: IEnterprise) => void,
    deleteHandler: (enterprise: IEnterprise) => void,
    downloadHandler: (accountId: IEnterprise) => void
}) {
    return <Fragment>
        {props.list?.length > 0 ? <div className="row">
        <table className="table">
            <thead>
                <tr>
                    <th className="small-header" scope="col">#</th>
                    <th scope="col">Name</th>
                    <th className="small-header" scope="col">Delete</th>
                    <th className="small-header" scope="col">Download</th>
                </tr>
            </thead>
            <tbody>
                {props.list.map((enterprise: IEnterprise, index: number) => <tr key={enterprise.id}>
                    <td onClick={() => props.selectHandler(enterprise)}>{index + 1}</td>
                    <td onClick={() => props.selectHandler(enterprise)}>{enterprise.name}</td>
                    <td onClick={() => props.deleteHandler(enterprise)}>Delete</td>
                    <td onClick={() => props.downloadHandler(enterprise)}>Download</td>
                </tr>)}
            </tbody>
        </table>
    </div> : <div className="flex align-center">Enterprise account(s) not found</div>}
    </Fragment>
}