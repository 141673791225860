import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../helpers/local-storage-helper";
import { mainActions } from "../store/main-slice";

export function useHttp<TData>(expectBlob: boolean = false) {
    const { REACT_APP_API_URL } = process.env;
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<number>(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const request = useCallback(async (path: string, method: string = 'GET', body: any = null, headers: any = null) => {
        try {
            reset();
            headers = headers || {
                'Content-Type': 'application/json'
            }
            headers['Authorization'] = 'bearer ' + getToken();
            const response = await fetch(`${REACT_APP_API_URL}${path}`, {
                method: method,
                body: body,
                headers: headers
            });
            setStatus(response.status);
            if (response.ok) {
                return (expectBlob
                    ? await response.blob()
                    : await response.json()) as TData;
            }

            if (response.status === 401) {
                removeToken();
                navigate('/', { replace: true });
                return null;
            }
            if (response.status === 403) {
                dispatch(mainActions.setMessage({ text: `You don't have a permission to access this resource`, isError: true }));
                return null;
            }
            const content:any = await response.json();
            throw new Error(content?.Title || content?.title || content?.toString() || 'Something went wrong');
        }
        catch (e:any) {
            dispatch(mainActions.setMessage({ text: `${e.message || e}`, isError: true }));
            return null;
        }
        finally {
            setLoading(false);
        }
    }, [REACT_APP_API_URL, expectBlob, navigate, dispatch]);

    const reset = () => {
        setStatus(0);
        setLoading(false);
    }

    return [
        request, status, loading, reset
    ] as const
}