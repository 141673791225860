import { Link } from "react-router-dom"

export function Policy() {
    return <div className="info-wrapper">
        <div className="info-content">
            <Link to="/home" className="back"><span className="material-icons">arrow_back</span>&nbsp;Back</Link>
            <p><strong>Click Reporting Privacy Policy&nbsp;(April 2022)</strong></p>
            <p>&nbsp;</p>
            <p><strong>Introduction&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>This Privacy Policy is applicable to Click Reporting Limited and its affiliates (together as &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;) as related to our activities, which collectively include:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>the use of our website clickreporting.ai,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>the use of our services including our free trial, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>marketing campaigns.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight: 400 }}>Our website and services may contain links to other third-party websites and we are not responsible for the collection and processing of personal information or content of such third parties. Any links do not imply that we endorse or review such third-party content.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>This Privacy Policy sets out the essential details relating to your personal data relationships with us as:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>website visitors,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>existing or prospective customers,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>end users,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>job applicants,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>partners, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>data subjects in other cases.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight: 400 }}>Customers and partners contract the use of our services and give access to their employees and other third parties, as solely decided by them, by creating users who can access the products and services with their email address and other credentials. The customers&rsquo; administrators grant end users roles, which result in different permissions and access rights to the information held in the customer account.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Personal Information We Collect</strong></p>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>Personal data means any information relating to a natural person and which can be used to identify the natural person. We also collect anonymous and aggregated data or non-personal information that can be processed according to this Privacy Policy regardless of their nature.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Information you choose to provide to us&nbsp;</strong></p>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>We may ask you to provide, or you may choose to provide, personal information when:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you request a free trial to evaluate our services,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you connect with us directly via email, support, social media, phone calls or video conferencing platforms,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>we or customer account administrators grant you access to our applications and services,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you, customer account administrators or end users upload or enter personal information into the application,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you participate in a marketing/sales promotion,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you register or attend our product launches, webinars or other events, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you contract the use of our services.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight: 400 }}>The personal information we collect may include first and last name, title, phone number, business email address, company name.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>When you are an end user of our applications, we collect your name, email address and any comments you make regarding our services.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>In addition, we may collect data uploaded by you or other users of our application that may be required to use our services. We expect all users to follow their organization&rsquo;s privacy policy and any applicable regulatory requirements when uploading, accessing and using personal information in relation to our services. The data uploaded may include personal information like:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>employee or other representatives&rsquo; names, email addresses, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>end user names, email addresses, company name or other personal data necessary for our services.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight: 400 }}>As a job applicant, we may also collect your resume, cover letter or other personal data necessary for recruitment or to take necessary steps prior to entering into a contract with us. We may ask you to provide your consent to keep your details in our database for the purposes of future recruitment processes.</span></p>
            <p>&nbsp;</p>
            <p><strong>Information we collect automatically&nbsp;</strong></p>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>We collect information about your visits to our website and your use of our services when you land on any of our web pages through business intelligence tools, cookies or similar tracking technology.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>For more information about the data we process when you are using our website please see our Cookie Policy.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>The information collected about your visit to our website may include:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>access times,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>pages you view,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>links you click on,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>search terms you enter,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>actions you take in connection with any of the visited pages,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>your device information such as IP address, location, browser type and language,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>the Uniform Resource Locator (URL) of the website that referred you to our website, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>the URL you browse away from our pages if you click on an external link. The information collected about your use of the services includes in particular:&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>access times and metadata,&nbsp;</span></li>
            </ol>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>number of processed pages,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>performance metrics,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>usage of extensions,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>number of workspaces and queues,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>incoming email metadata,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>user interface interaction details,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>type of documents, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>language and currency.</span></li>
            </ol>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>We may also collect information when you open email messages from us or click on links within those email messages.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Information we may collect from third parties&nbsp;</strong></p>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>We may combine the information we collect as a result of your direct interactions with us with information obtained through other third party sources, such as third-party tools gathering business data.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>We may also process the information contained in documents uploaded by end users when using our services.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>The personal information collected includes your name, email address, business address, job title, company name, and telephone number. The information contained in uploaded documents may contain name, business name, contact details, payment details and scope or subject of performance.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>If you choose to provide us with a third party&rsquo;s personal Information when using our services, you represent that you have a legal basis to do so, including the third party&rsquo;s consent if needed.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>How We Use Personal Information&nbsp;</strong></p>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>We use the personal Information to:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Deliver the contracted services, including the free trial, and allow full use of the functionality as purchased by the customers.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Deliver support and training to end users and/or carry out the transactions you have requested.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>To communicate with you directly through emails, calls, chats, video conferencing or other channels and tools.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Process payments for service subscriptions.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Analyse user clicks and usage of our website and services to improve user experience and maximize usage of our services. The uploaded documents may be further processed and used for research, development and training of our services and artificial intelligence.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Manage our website and services in order to maintain and deliver the contracted functionality.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Enforce our terms of use and/or separate contracts (if applicable) with you.</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Send communications to you about:&nbsp;</span></li>
            </ol>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>new features and upgrades,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>our services and offerings,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>event announcements,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>product notices and changes to our terms and policies,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>particular programs in which you have chosen to participate,&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>promotional offers and surveys, and&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>scheduling demos and managing free trials.&nbsp;</span></li>
            </ol>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Advertise and market our services, including delivering interest-based advertisements on the website and other sites or content syndication platforms and websites.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Carry out market research to understand how to improve our services and their delivery.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Create and manage marketing campaigns.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Generate sales leads and increase our market share.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Prevent fraud and other prohibited or illegal activities.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Protect the security or integrity of our websites, our business or services and related platforms.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Otherwise, as disclosed to you at the point of collection or as required or permitted by law.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight: 400 }}>Please note that sometimes we may record video conferencing calls in which you participate in order to analyse and improve our offerings and communication skills. If we do so, we will be announcing it at the beginning of the conference call.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>We do not sell your information, including any statistical and analytical data, to any third party.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>How We Share Personal Information&nbsp;</strong></p>
            <p>&nbsp;</p>
            <p><strong>Service Providers&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>We use third parties to help us provide our services. They will have access to your information as collected by the Website or the Application, as reasonably necessary to perform the contracted tasks on our behalf. We sign contractual agreements to obligate them to protect the personal information based on the latest legal developments and best industry standards, to only use it to deliver the contracted services to us, to prohibit them from selling it and not to disclose it without our knowledge and permission.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>If you want to learn more about our service providers, please see our Data Sub-processor Schedule in our Terms of Use.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Legal disclosures&nbsp;</strong></p>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight: 400 }}>It is possible that we may need to disclose personal information when required by law or other legal processes as identified in the applicable legislation.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>If we receive any order for disclosure of personal information, we will use all reasonable efforts to redirect the request directly to the customer or end user.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Change in Control&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>We can also share your personal data as part of a sale, merger or change in control or in preparation for any of these events. Any other entity which buys us or a substantial part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>How We Secure Personal Information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>We are committed to protecting the security of all of the personal information we collect and use.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>We use a variety of physical, administrative and technical safeguards designed to help protect it from unauthorized access, use and disclosure. We have implemented best-practice standards and controls in compliance with internationally recognized security frameworks. We use encryption technologies to protect data at rest and in transit.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>If you want to learn more about our security, please see our Security Measures Schedule in our Terms of Use.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>What Are Your Rights</strong></p>
            <p><span style={{ fontWeight: 400 }}>We offer the following rights to all individuals regardless of their location or applicable privacy regulations.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Right to access your personal information or request a copy&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>Subject to applicable law, you have the right to know whether we hold your personal information, obtain information about what personal information we process and why or to obtain a copy of your personal information. If you are an end user of our services, you can log in to see the personal information in the account or approach your employer for more information. You also have a right to know how we use the personal information, whether we disclose it and how we protect it. This Privacy Policy contains general information about the processing of personal information. We may also have additional privacy notices and statements available to you at the point of providing information to us directly or when communicating with you about the processing.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Right to change or correct your personal information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>You have the right to request that inaccurate personal information is updated or corrected.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Right to delete or erase your personal information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>Subject to applicable law, you have the right to request deletion of your personal information that is no longer necessary for the purposes underlying the processing, processed based on withdrawn consent, or if the processing is not compliant with applicable laws.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Right to object to the processing of your personal information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>You have the right to object to our processing of your personal information based on our legitimate interest such as for direct marketing purposes. This means that we will stop using your personal information for these purposes, unless we demonstrate compelling legitimate grounds for the processing which override the request.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Right to ask us to restrict the processing of your personal information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>You may have the right to ask us to limit the way that we use your personal information, where you contest the accuracy of your personal information, the processing is non-compliant with applicable law and you prefer to restrict the processing instead of the erasure of personal information or when you objected to the processing pending the verification of our legitimate grounds.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Right to export your personal data or request portability of personal information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>You have the right to request that we export to you in a machine-readable format all of the personal information you have provided to us about you (which does not include information derived from the collected information), where the processing is based on your consent or contract with you and is carried out by automated means.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>We will communicate back to you within reasonable timelines the result of your request. We may not be able to satisfy your request in full, but we will inform you of the reasons and further actions available to you.</span></p>
            <p><span style={{ fontWeight: 400 }}>If you would like to exercise any of the rights described above, please contact us at </span><span style={{ fontWeight: 400 }}>info@clickreporting.ai</span><span style={{ fontWeight: 400 }}>.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>How Long We Keep Personal Information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>We retain information as long as it is necessary to provide our services to you and our customers, subject to any legal obligations to retain such information further. The processing of the personal information contained in documents uploaded into our Cloud Based Technology may also be subject to agreed retention terms with our Customers or their request to erase the personal information.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>We may also retain information to comply with the law, prevent fraud, collect fees, resolve disputes, troubleshoot problems, assist with investigations, enforce our Terms of Use or separate contracts and take other actions permitted by law.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>The information we retain will be handled in accordance with this Privacy Policy.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>Information connected to you that is no longer necessary and relevant to provide our services may be de-identified or aggregated with other non-personal data to provide insights which are commercially valuable to us, such as statistics of the use of the services.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong>Other Important Information&nbsp;</strong></p>
            <p><span style={{ fontWeight: 400 }}>Automated decision making, including profiling&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>Due to our obligations under export control regulations, we may deny access to our services if you or your company are listed on the relevant websites.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>Based on our legitimate interest to advertise only relevant information about our services, events and news we may create your personal profile and adjust our offerings according to your territory, company or job title.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>Data transfers&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>We may process data in different locations according to contracts made with the customers and when using our service providers. We rely on legally-provided mechanisms to lawfully transfer data across borders, such as adequacy decisions, contracts incorporating data protection and sharing obligations and other appropriate safeguards as required by applicable law.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>We will only collect and process personal data about you where we have a lawful reason for its processing.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>Marketing</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>When you visit our website and fill your personal information in any form, we collect and use it for marketing purposes with your consent. If we combine the information we use for marketing purposes with information obtained through third party sources, we rely on our legitimate interest for marketing activities.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>How to select your communications preferences&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>You may choose to receive or not receive marketing communications from us. To stop receiving marketing communications, please click the &ldquo;Unsubscribe&rdquo; link in the email we sent you. Depending on the type of information we use, your choice will be considered a withdrawal of your consent or an objection to processing based on our legitimate interest.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>Even if you opt-out of receiving marketing communications, we may still communicate with you in connection with security and privacy issues, servicing your account, fulfilling your requests, or administering any promotion or any program in which you may have elected to participate.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>Processing of end users&rsquo; information&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>As an end user, you consent to our collection of your personal information when you log in for the first time. However, your employer has control of the account and may upload and share additional personal information. It is your employer&rsquo;s responsibility to ensure the collection, use and sharing of the personal information uploaded to the Application complies with all applicable legislation.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>For more information, you can review our Terms of Use.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>Processing of information contained in the uploaded documents&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>The uploaded documents will be processed to provide you structured data by our automated data-extraction tool using deep learning approach and artificial intelligence. We are processing customer-provided documents for the primary purpose of data capture, based on instructions of our customers. Based on the nature of the data and the balance test, we have a full reason to believe that this processing is fully compliant.&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>There are several things that you might need to do depending on your situation and jurisdiction. We recommend taking these steps:&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Make sure your terms of service or privacy policy properly communicate to your customers, business partners and/or employees that you are using us as a service provider and how the processing works (including artificial intelligence and machine learning).&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Being transparent is one of the most important data protection principles. We recommend you ensure your policies are up to date and clear to your readers.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight: 400 }}>Recommended information to be handed over to your business counterparts or customer:</span></p>
            <ol>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We use Click Reporting Limited and its affiliates (&ldquo;Click Reporting&rdquo;) to extract relevant information from documents. The documents may be retained and used by Click Reporting for limited time on the basis of legitimate interests as part of a training dataset used for research, development and learning of Click Reporting&rsquo;s artificial intelligence models employed to process the documents.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>If you want us to process documents of which you are not the owner or direct participant make sure that you are entitled to transmit the documents to us and, if applicable, ask for the consent of the participating persons with this processing. The consent should cover the transmission of data to Click Reporting for the data-extraction as well as for the artificial intelligence learning purposes. Please note that a consent under the applicable legislation must be expressed and informed.&nbsp;</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>To avoid any doubts, this does not apply to documents containing your data.&nbsp;</span></li>
            </ol>
            <p>&nbsp;</p>
            <p><strong><em>Processing based on your consent&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact us at </span><span style={{ fontWeight: 400 }}>info@clickreporting.ai</span><span style={{ fontWeight: 400 }}>.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>Changes to Our Privacy Policy&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>We may change and update this Privacy Policy from time to time as necessary. We will notify you of any substantial changes and make sure the notification is made in an appropriate way which ensures that you acknowledge them.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><strong><em>Contact Us&nbsp;</em></strong></p>
            <p><span style={{ fontWeight: 400 }}>You may contact us to exercise any of your rights or ask for more information about your personal information and our privacy practices by contacting us at </span><span style={{ fontWeight: 400 }}>info@clickreporting.ai </span><span style={{ fontWeight: 400 }}>or 22 The Residences Scholes Lane, Prestwich, Manchester M25 0NT.</span></p>
            <p><br /><br /></p>
        </div>
    </div>
}