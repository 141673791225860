import React, { useState, useEffect } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from './LogoCarousel.module.scss';

import Bpe from '../../assets/logos/Bpe.png';
import Elan from '../../assets/logos/Elan.png';
import HW from '../../assets/logos/HW.png';
import Stevens from '../../assets/logos/Stevens.png';
import Gunnercooke from '../../assets/logos/Gunnercooke.png';
import Landsmiths from '../../assets/logos/Landsmiths.png';
import Searchbureau from '../../assets/logos/Searchbureau.png';

export default function LogoCarousel() {
  const originalLogos = [
    { src: Bpe, alt: "Bpe" },
    { src: Elan, alt: "Elan" },
    { src: HW, alt: "HW" },
    { src: Stevens, alt: "Stevens" },
    { src: Gunnercooke, alt: "Gunnercooke" },
    { src: Landsmiths, alt: "Landsmiths" },
    { src: Searchbureau, alt: "Searchbureau" },
  ];

  const interval = 3000;
  const repeatTimes = 20;
  const logos = Array(repeatTimes).fill([...originalLogos]).flat();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(getVisibleSlides(window.innerWidth));

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((current) => {
        const nextSlide = (current + 1) % logos.length;
        if (nextSlide === originalLogos.length * repeatTimes) {
          setTimeout(() => {
            setCurrentSlide(0);
          }, 0);
        }
        return nextSlide;
      });
    }, interval);

    return () => clearInterval(slideInterval);
  }, []);

  useEffect(() => {
    function handleResize() {
      setVisibleSlides(getVisibleSlides(window.innerWidth));
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getVisibleSlides(windowWidth: number) {
    const mobileBreakpoint = 480;
    const ipadBreakpoint = 1024;
    if (windowWidth <= mobileBreakpoint) {
      return 2;
    } else if (windowWidth <= ipadBreakpoint) {
      return 4;
    } else {
      return 6;
    }
  }

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      totalSlides={logos.length}
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      currentSlide={currentSlide}
      interval={interval}
      infinite={true}
      isPlaying={true}
      dragEnabled={false}
      touchEnabled={false}
    >
      <Slider className={styles.logoSlider}>
        {logos.map((logo, index) => (
          <Slide index={index} key={index}>
            <div className={styles.logoContainer}>
              <img src={logo.src} alt={logo.alt} className={styles.logo} />
            </div>
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
}
