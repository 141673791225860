import { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.scss";

export function Modal(props: {
    children: any,
    close?: () => void,
    hasCloseButton?: boolean,
    width?: number,
    height?: number
}) {
    return <Fragment>
        {ReactDOM.createPortal(
            <div onClick={props.hasCloseButton ? undefined : props.close} className={styles.modal}>
                <div style={!props.width ? {} : { maxWidth: `${props.width}px`, width: `${props.width}px`, height: `${props.height}px` }}
                    className={styles['modal-content']}>
                    {props.hasCloseButton && <div className={styles.close} onClick={props.close}>
                        <span className="material-icons">close</span>
                    </div>}
                    {props.children}
                </div>
            </div>,
            document.getElementById('portal')!
        )}
    </Fragment>
}