import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { isAuthenticated, isInRole, removeToken, setToken } from '../../helpers/local-storage-helper';
import { mainActions } from '../../store/main-slice';
import { RootState } from '../../store';
import logo from '../../assets/logo.svg';
import { LoginModal } from '../login-modal/LoginModal';
import { useHttp } from '../../hooks/use-http';

export function Header() {
    const loggedIn: boolean | null = useSelector<RootState, boolean | null>(state => state.main.loggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showMenuOnMobile, setShowMenuOnMobile] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [request] = useHttp<string>(false);

    const getTokenFromHash = useCallback(function (): string | null {
        return new URLSearchParams(location.hash.substring(1))?.get("token")
    }, [location.hash]);

    useEffect(() => {
        const newToken = getTokenFromHash();
        if (!!newToken) {
            setToken(newToken);
        }
        const loggedIn: boolean = isAuthenticated();
        dispatch(mainActions.setLoggedIn(loggedIn));
        if (loggedIn && !!newToken) {
            setShowMenuOnMobile(false);
            navigate("/home", { replace: true });
            return;
        }
    }, [dispatch, navigate, getTokenFromHash]);

    const logoutHandler = function () {
        removeToken();
        dispatch(mainActions.setLoggedIn(false));
        setShowMenuOnMobile(false);
        navigate('/');
    }

    const goToHomePage = function (): void {
        setShowMenuOnMobile(false);
        navigate("/home", { replace: true });
    }

    const handleMenuItemClick = function (showMenu: boolean) {
        setShowMenuOnMobile(showMenu);
    }

    const handleSignInClick = function () {
        setShowLoginModal(true);
    }

    const hideLoginModal = function () {
        setShowLoginModal(false);
        setShowMenuOnMobile(false);
    }

    const submitSettingsForm = async function () {
        const response: string | null = await request('/payments/portal', 'POST', null, null);
        if (!!response && response.startsWith('http')) {
            window.location.replace(response);
        }
    }

    return <header>
        <div className="container-fluid">
            <div className="main-logo header-item-left" onClick={goToHomePage}>
                <img src={logo} alt="" />
            </div>
            <button className="navbar-toggler" type="button" onClick={() => handleMenuItemClick(!showMenuOnMobile)}>
                <i className="material-icons">menu</i>
            </button>
            <div className={`navbar-collapse header-item-right ${showMenuOnMobile && 'collapsed'}`}>
                <ul className="nav">
                    {loggedIn && isInRole('SuperAdmin') &&
                        <li onClick={() => handleMenuItemClick(false)}><NavLink to={"/enterprise"}>SUPERADMIN</NavLink></li>}
                    {loggedIn && <li onClick={() => handleMenuItemClick(false)}><NavLink to={"/home"}>TITLE REPORT</NavLink></li>}
                    {loggedIn && !isInRole('Enterprise') &&
                        <li><button className='menu-item' onClick={submitSettingsForm}>SETTINGS</button></li>}
                    {loggedIn && !isInRole('Enterprise') &&
                        <li className={isInRole('SuperAdmin') ? "hide-under hide-under--900" : ""}
                            onClick={() => handleMenuItemClick(false)}><NavLink to={"/subscriptions"}>CHANGE PLAN</NavLink></li>}
                    {!loggedIn && <li onClick={() => handleMenuItemClick(false)}><NavLink to={"/pricing"}>PRICING</NavLink></li>}
                    {!loggedIn && <li onClick={() => handleMenuItemClick(false)}><NavLink to={"/how-it-works"}>HOW IT WORKS</NavLink></li>}
                    {!loggedIn && <li onClick={() => handleMenuItemClick(false)}><NavLink to={"/about"}>ABOUT</NavLink></li>}
                    {!loggedIn && <li onClick={() => handleMenuItemClick(false)}><NavLink to={"/resources"}>RESOURCES</NavLink></li>}
                    <li>
                        {loggedIn && <button className="btn btn--black" onClick={logoutHandler}>
                            <span>Sign Out</span>
                        </button>}
                        {!loggedIn && <button className="btn btn--normal-text" onClick={handleSignInClick}>
                            Sign in</button>}
                        {!loggedIn && showLoginModal && <LoginModal close={hideLoginModal}></LoginModal>}
                    </li>
                </ul>
            </div>
        </div>
    </header>
}