import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Home } from './pages/home/Home';
import { Welcome } from './pages/welcome/Welcome';
import { RootState } from './store';
import { Header } from './components/header/Header';
import './App.scss';
import { Footer } from './components/footer/Footer';
import { Message } from './components/message/Message';
import { useEffect } from 'react';
import { useHttp } from './hooks/use-http';
import { mainActions } from './store/main-slice';
import { Policy } from './pages/policy/Policy';
import { Terms } from './pages/terms/Terms';
import { ScrollToTop } from './components/scroll-to-top/ScrollToTop';
import { IMessage } from './models/message.model';
import { Pricing } from './pages/pricing/Pricing';
import { StandardLayout } from './components/standard-layout/Standard-layout';
import { Login } from './pages/login/Login';
import { Register } from './pages/register/Register';
import { About } from './pages/about/About';
import { Enterprise } from './superadmin/pages/enterprise/Enterprise';
import { isInRole } from './helpers/local-storage-helper';
import { HowItWorks } from './pages/how-it-works/HowItWorks';
import { Pending } from './pages/pending/Pending';
import { Resources } from './pages/resources/Resources';
import { ResourceDetail } from './pages/resource-detail/ResourceDetail';

function App() {

  const loggedIn: boolean | null = useSelector<RootState, boolean | null>(state => state.main.loggedIn);
  const message: IMessage | null = useSelector<RootState, IMessage | null>(state => state.main.message);
  const dispatch = useDispatch();
  const pingRequest = useHttp<string>(false)[0];
  const [paymentSuccessKey, paymentFailKey, emailVerifiedKey] = ['paymentSuccess', 'paymentFailed', 'emailVerified'];

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get(paymentSuccessKey)) {
      dispatch(mainActions.setMessage({ text: 'Your payment was successful', isError: false }));
    }

    if (query.get(paymentFailKey)) {
      dispatch(mainActions.setMessage({ text: 'Your payment was unsuccessful', isError: true }));
    }

    if (query.get(emailVerifiedKey)) {
      dispatch(mainActions.setMessage({ text: 'Your email address has been verified', isError: false }));
    }
  }, [dispatch, paymentSuccessKey, paymentFailKey, emailVerifiedKey]);

  useEffect(() => {
    (async () => {
      await pingRequest('/accounts/ping', 'GET');
    })();
  }, [pingRequest]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#043C50'
      }
    }
  });

  return (
    <main className="main-wrap">
      <MuiThemeProvider theme={theme}>
        {!!message && <Message message={message}></Message>}
        <ScrollToTop />
        <Header></Header>
        {(loggedIn !== null) &&
          <Routes>
            <Route path="/" element={<Welcome></Welcome>} />
            <Route element={<StandardLayout />}>
              {loggedIn && <Route path="/home" element={<Home></Home>} />}
              {loggedIn && !isInRole('Enterprise') && <Route path="/subscriptions" element={<Pricing loggedIn={loggedIn}></Pricing>} />}
              {!loggedIn && <Route path="/pricing" element={<Pricing loggedIn={loggedIn}></Pricing>} />}
              {!loggedIn && <Route path="/how-it-works" element={<HowItWorks></HowItWorks>} />}
              {!loggedIn && <Route path="/about" element={<About></About>} />}
              {!loggedIn && <Route path="/resources" element={<Resources></Resources>} />}
              {!loggedIn && <Route path="/resources/:id" element={<ResourceDetail></ResourceDetail>} />}
              {loggedIn && isInRole('SuperAdmin') && <Route path="/enterprise" element={<Enterprise></Enterprise>} />}
              <Route path="/policy" element={<Policy></Policy>} />
              <Route path="/terms" element={<Terms></Terms>} />
              {!loggedIn && <Route path="/register" element={<Register></Register>} />}
              {!loggedIn && <Route path="/reset-password" element={<Register></Register>} />}
              {!loggedIn && <Route path="/pending" element={<Pending></Pending>} />}
              {!loggedIn && <Route path="/login" element={<Login></Login>} />}
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>}
        <Footer></Footer>
      </MuiThemeProvider>
    </main>
  );
}

export default App;
