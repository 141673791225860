import { useNavigate } from "react-router-dom";
import bannerImage from '../../assets/how-it-works-banner.png';
import landRegistryImage from '../../assets/hiw/hm-land-registry.png';
import inputImage from '../../assets/hiw/input.png';
import outputImage from '../../assets/hiw/output.png';
import reportImage from '../../assets/hiw/report.png';
import reviewImage from '../../assets/hiw/review.png';
import styles from "./HowItWorks.module.scss";
import { IHowItWorks } from "../../models/how-it-works.model";
import { useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { InnerBanner } from "../../components/inner-banner/InnerBanner";

const steps: IHowItWorks[] = [
    {
        order: 1,
        title: 'Select the pdf',
        description: 'Title Register from your system, received by email or downloaded from the Land Registry',
        image: landRegistryImage,
        centerImage: true
    },
    {
        order: 2,
        title: 'Drag or upload',
        description: 'the Register into Click Reporting',
        image: inputImage,
        centerImage: false
    },
    {
        order: 3,
        title: 'Your Word Report',
        description: `will download within seconds. To help you find the data that had been automatically 
        extracted in the summary, the imported information will be highlighted yellow. 
        Areas to insert your advice will be red.`,
        image: reportImage,
        centerImage: false
    },
    {
        order: 4,
        title: 'Review the report',
        description: 'and remove the highlighting. Click on this video to watch the process in real time!',
        image: reviewImage,
        centerImage: false,
        hasVideoModal: true
    },
    {
        order: 5,
        title: 'All remaining',
        description: 'for you to do is add your expert advice and send on to your client',
        image: outputImage,
        centerImage: false
    },
];

export function HowItWorks() {

    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const goToPricingPage = function () {
        navigate('/pricing');
    }

    const handleStepClick = function (step: IHowItWorks) {
        if (!step.hasVideoModal) {
            return;
        }
        setShowVideoModal(true);
    }

    const buildStepImageClassName = function (step: IHowItWorks, mode: string) {
        let className: string = styles[mode];
        if (step.centerImage) {
            className += ` ${styles.center}`;
        }
        if (step.hasVideoModal) {
            className += ` ${styles.pointer}`;
        }
        return className;
    }

    return <div>
        <InnerBanner title="How it works"
            subTitle="Generating a Title Report from a Title Register with Click Reporting is incredibly simple and takes literally seconds"
            bannerImage={bannerImage}></InnerBanner>
        <div className={styles.content}>
            {steps.map(step => <div key={step.order} className={`${styles.box} col-12`}>
                <div className="content-wrap flex align-middle align-justify">
                    <div className={styles['order-wrapper']}>
                        <div className={styles.order}><span>{step.order}</span></div>
                        <img src={step.image}
                            className={buildStepImageClassName(step, 'mobile-only')}
                            onClick={() => handleStepClick(step)}
                            alt="" />
                        <p><span className="font--bold">{step.title} </span>
                            {step.description}
                        </p>
                    </div>
                    <img src={step.image}
                        className={buildStepImageClassName(step, 'desktop-only')}
                        onClick={() => handleStepClick(step)}
                        alt="" />
                </div>
            </div>)}
            <div className="flex align-center">
                <button className="btn btn--black btn--with-icon" onClick={goToPricingPage}>
                    <span>START FREE TRIAL</span>
                </button>
                {showVideoModal && <Modal width={550} height={314} close={() => setShowVideoModal(false)}>
                    <iframe title="title extraction" width="100%" height="100%" src="https://www.youtube.com/embed/tCGFnLw2E2g"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}></iframe>
                </Modal>}
            </div>
        </div>
    </div>
}