import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../helpers/local-storage-helper";
import LogoCarousel from './LogoCarousel'; 
import bannerImage from '../../assets/image-01.png';
import realEstateImage from '../../assets/icn/real-estate.svg';
import challengeImage from '../../assets/icn/challenge.svg';
import processImage from '../../assets/icn/process.svg';
import aiImage from '../../assets/icn/ai.svg';
import automatingImage from '../../assets/icn/automating.png';
import machineLearningImage from '../../assets/icn/machine-learning.svg';
import buildingImage from '../../assets/building.png';
import { SignUpButton } from "../../components/sign-up-button/Sign-up-button";
import { WatchVideoButton } from "../../components/watch-video-button/Watch-video-button";
import { FreeTrialBadge } from "../../components/free-trial-badge/FreeTrialBadge";
import { ContactForm } from "../../components/contact-form/ContactForm";
import { ContactFormType } from "../../models/enums/contact-form-type";
import styles from "./Welcome.module.scss";

export function Welcome() {
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated()) {
            navigate("/home", { replace: true });
            return;
        }
    }, [navigate]);

    return <Fragment>
        <section className="banner-main">
            <div className="container-fluid">
                <div className={`${'row'} ${styles.marginBottom100}`}>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <div className="desktop-only">
                                <FreeTrialBadge textColor="#fff" background="#5293a5"></FreeTrialBadge>
                            </div>
                            <h3>For Real Estate Lawyers In England and Wales</h3>
                            <h1>Click Reporting</h1>
                            <h2>Automating Title Extraction</h2>
                            <p>Generate an accurate title summary <br />using our powerful AI solution at the click of a button</p>
                            <div className="mobile-only">
                                <FreeTrialBadge textColor="#fff" background="#5293a5"></FreeTrialBadge>
                            </div>
                            <div className="flex">
                                <SignUpButton isMobile={false}></SignUpButton>
                                <WatchVideoButton isMobile={false}></WatchVideoButton>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src={bannerImage} alt="" />
                        <SignUpButton isMobile={true}></SignUpButton>
                        <WatchVideoButton isMobile={true}></WatchVideoButton>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${'section'} ${styles.logosSection}`}>
            <div className={styles.container}>
                <p className={styles.box}>
                    <span className={styles.trustedBy}>Trusted By</span>
                </p>
                <br />
                <div className={styles.logos}>
                    <LogoCarousel />
                </div>
            </div>
        </section>
        <section className="section section--02">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>The Future is here</h1>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <img src={realEstateImage} className="icn" alt="" />
                            <p><span className="font--bold">Real estate </span>
                                transactions require law firms to summarise title documents.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <img src={challengeImage} className="icn" alt="" />
                            <p><span className="font--bold">The challenge </span>
                                facing law firms is that reviewing and summarising
                                these documents accurately is a time consuming and arduous task.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <img src={processImage} className="icn" alt="" />
                            <p><span className="font--bold">The process </span>
                                is long overdue for modernisation. Click Reporting is revolutionising the process.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <img src={automatingImage} className="icn" alt="" />
                            <p><span className="font--bold">Automating </span>
                                the review and summary process is the only way to stay competitive.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <img src={aiImage} className="icn" alt="" />
                            <p><span className="font--bold">Our cutting edge AI </span>
                                technology allows you to simply upload a pdf title register.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="content-wrap">
                            <img src={machineLearningImage} className="icn" alt="" />
                            <p><span className="font--bold">Our machine learning </span>
                                software will automatically extract the relevant sections to produce
                                your summary as a word document in seconds.
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>It's as simple as that!</h3>
                    </div>
                    <div className={`${'col-12'} ${styles.testimonialSection}`}>
                        <div className={styles.container}>
                            <p className={styles.box}>
                                <span className={`${styles.quote} ${styles.leftQuote}`}>“</span>
                                <span className={styles.text}>The Click Reporting platform has saved me so much time. I had set aside a day and a half to prepare a title report for a site with 11 titles and using Click Reporting I prepared the report in about 45 minutes. I can't imagine going back to the old way</span>
                                <span className={`${styles.quote} ${styles.rightQuote}`}>”</span>
                            </p>
                            <br />
                            <p className={styles.author}>
                                <span className={styles.name}>M.Hallam</span>
                                <br />
                                <span className={styles.title}>Partner, Gunnercooke LLP</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="banner-box">
                            <h2>Click Reporting. Leading the way in AI document extractions for Real Estate lawyers.</h2>
                            <img src={buildingImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section section--04">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h3 className={styles.contactFormText}>If you have any questions, please complete the form</h3>
                    </div>
                    <div className="col-12 col-md-6">
                        <ContactForm formType={ContactFormType.homePage}></ContactForm>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
}