import { NavLink } from "react-router-dom";
import styles from "./InnerBanner.module.scss";

export function InnerBanner(props: { bannerImage: string, title: string, subTitle: string }) {
    return <div className={styles.banner}>
    <div className={styles["banner-header"]}>
        <NavLink to={"/"}><span className={styles.bold}>Home</span></NavLink>
        <span className={`${styles.divider} material-icons`}>chevron_right</span>
        <span>{props.title}</span>
    </div>
    <div className={styles["banner-content"]}>
        <div className="flex flex-column">
            <h2>{props.title}</h2>
            <p>{props.subTitle}</p>
        </div>
        <img src={props.bannerImage} alt="" />
    </div>
</div>
}