import { useHttp } from "../../hooks/use-http";
import { IPricingPlan } from "../../models/pricing-plan.model";
import styles from "./PricingPlan.module.scss";

export function PricingPlan(props: { model: IPricingPlan, isCurrent: boolean, loggedIn: boolean, onContact?: () => void }) {

    const origin: string = window.location.origin;
    const activeClassNameOrEmpty: string = props.isCurrent ? styles["pricing-plan-item--active"] : '';
    const [request] = useHttp<string>(false);

    const submitForm = async function () {
        const response: string | null = await request('/account-settings/plan', 'POST', JSON.stringify({
            origin: origin,
            type: props.model.type
        }), null);
        if (!!response && response.startsWith('https')) {
            window.location.replace(response);
        }
    }

    return <div className={`${styles["pricing-plan-item"]} ${activeClassNameOrEmpty}`}>
        <div className={styles["pricing-plan-item__icon-wrapper"]}>
            <h3 style={{ color: props.model.price > 0 ? undefined : props.model.iconColor }}>{props.model.name}</h3>
            <div className={styles["pricing-plan-item__icon"]} style={{ backgroundColor: props.model.iconColor }}>
                <span className="material-icons">{props.model.iconName}</span>
            </div>
        </div>
        <div className={styles["pricing-plan-item__price"]}>
            <h3>{props.model.name}</h3>
            <div className={props.model.price > 0 ? '' : styles.hidden}>
                <h2>&#163;{props.model.price}<span>/month</span></h2>
                {!props.loggedIn && <div className={styles["pricing-plan-item__price__trial"]}
                    style={{ backgroundColor: '#ededed', color: '#393939' }}>
                    <span className={styles.text}>7 Day Free Trial</span>
                </div>}
            </div>
        </div>
        <div className={styles["pricing-plan-item__features"]}>
            <ul>
                {props.model.features.map((feature: string, index: number) => <li key={!!feature ? feature : index}>
                    <p>{!!feature ? <span className="material-icons">check</span> : <span>&nbsp;</span>}{feature}</p>
                </li>)}
            </ul>
        </div>
        <div>
            {props.model.price > 0 && <button disabled={props.isCurrent} onClick={submitForm}
                className={`${styles["pricing-plan-item__button"]} btn btn--secondary`}>
                <span>SELECT</span>
            </button>}
            {props.model.price <= 0 && <button onClick={props.onContact}
                className={`${styles["pricing-plan-item__button--contact"]} btn`}>
                <span>CONTACT SALES</span>
            </button>}
        </div>
    </div>
}