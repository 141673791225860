import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { useHttp } from "../../hooks/use-http";
import { mainActions } from "../../store/main-slice";
import uploadImage from '../../assets/icn/upload-icn.svg';
import downloadImage from '../../assets/icn/download-icn.svg';
import uploadButtonImage from '../../assets/icn/upload-btn-icn.png';

export function FileUploader(props: {
    extensions: string[],
    outputExtension: string,
    disable: boolean,
    onSuccess: () => void
}) {

    const dispatch = useDispatch();
    const [file, setFile] = useState<any>(null);
    const [allowedExtensions] = useState<string[]>(props.extensions || new Array<string>());
    // eslint-disable-next-line
    const [request, _, loading, reset] = useHttp<Blob>(true);

    useEffect(() => {
        if (props.disable) {
            dispatch(mainActions.setMessage({ text: 'You have reached your monthly upload limit.', isError: true }));
        }
    }, [props.disable, dispatch]);

    function hasValidFile(currentFile: any): boolean {
        return allowedExtensions.length === 0
            || allowedExtensions.indexOf(getFileExtension(currentFile.name)) > -1;
    }

    function getFileExtension(name: string): string {
        return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    }

    function getFileNameWithoutExtension(name: string): string {
        return name.substring(0, name.lastIndexOf('.'));
    }

    const handleFileChange = function (e: any) {
        const currentFile = e.target.files[0];
        if (!currentFile) {
            setFile(null);
            return;
        }
        const isValidFile: boolean = hasValidFile(currentFile);
        if (!isValidFile) {
            dispatch(mainActions.setMessage({
                text: `Format not supported. This file type is not supported. ` +
                    `Please select a ${allowedExtensions.join('/')} and try again`, isError: true
            }));
            return;
        }
        setFile(currentFile);
        handleFileUpload(currentFile, isValidFile);
    }

    const handleFileUpload = async (file: any, isValid: boolean) => {
        if (!file || !isValid || loading) return;
        const form = new FormData();
        form.append("input", file);
        const blob = await request('/files', 'POST', form, {});
        if (!!blob) {
            saveAs(blob, `${getFileNameWithoutExtension(file.name)}.${props.outputExtension}`);
            props.onSuccess();
        }
        reset();
        setFile(null);
    }

    return <div>
        <div className="upload-area">
            {!file && <Fragment>
                <img className="icn section-icn" src={uploadImage} alt="" />
                <p>Upload or drag and drop a file to <br />generate summary</p>
                <div className="type-file">
                </div>
                <div className="file-loading">
                </div>
                {!props.disable && <input type="file" onChange={handleFileChange} className="hidden-uploader" />}
                <button disabled={props.disable} className="btn btn--with-icon">
                    <img src={uploadButtonImage} className="icn" alt="" />
                    <span>UPLOAD FILE</span>
                </button>
            </Fragment>}
            {!!file && <Fragment>
                <img className="icn section-icn" src={downloadImage} alt="" />
                <p>Downloading File</p>
            </Fragment>}
            <div className="disclaimer">
                <span>Disclaimer:  Your report is  being created by automated extraction software.
                    Click Reporting Limited accept no responsibility or liability for any errors or omissions.
                    By downloading the report you are accepting the Click Reporting&nbsp;
                    <Link to="/terms">Terms of use</Link>&nbsp;and&nbsp;
                    <Link to="/policy">Privacy Policy</Link>.</span>
            </div>
        </div>
    </div>
}