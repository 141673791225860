import { Fragment, useEffect, useState } from "react";
import { BackButton } from "../../components/back-button/BackButton";
import { CancelSubscription } from "../../components/cancel-subscription/CancelSubscription";
import { ContactForm } from "../../components/contact-form/ContactForm";
import { EnterprisePlanDesktop } from "../../components/enterprise-plan/EnterprisePlanDesktop";
import { PricingPlan } from "../../components/pricing-plan/PricingPlan";
import { ScrollToTop } from "../../components/scroll-to-top/ScrollToTop";
import { useHttp } from "../../hooks/use-http";
import { AccountSettingType, IAccountSetting } from "../../models/account-settings.model";
import { ContactFormType } from "../../models/enums/contact-form-type";
import { PricingPlanType } from "../../models/enums/pricing-plan-type.enum";
import { IPricingPlan } from "../../models/pricing-plan.model";

const plans: IPricingPlan[] = [
    {
        name: 'Basic',
        iconName: 'person',
        iconColor: '#7d7d7d',
        price: 49,
        features: ['3 Monthly upload credits', 'Roll over unused monthly credits', 'Single user', 'Cancel at any time', ''],
        type: PricingPlanType.basic
    },
    {
        name: 'Popular',
        iconName: 'star',
        iconColor: '#cf422c',
        price: 99,
        features: ['10 Monthly upload credits', 'Roll over unused monthly credits',
            'Single user', 'Cancel at any time', ''],
        type: PricingPlanType.popular
    },
    {
        name: 'Executive',
        iconName: 'manage_accounts',
        iconColor: '#043c50',
        price: 199,
        features: ['30 Monthly upload credits', 'Roll over unused monthly credits',
            'Single user', 'Cancel at any time', 'Premium Support*'],
        type: PricingPlanType.executive
    },
    {
        name: 'Enterprise',
        iconName: 'apartment',
        iconColor: '#5293a5',
        price: 0,
        features: ['Bespoke Package', 'Roll over unused monthly credits',
            'Multiple users', 'Cancel at any time', 'Premium Support*'],
        type: PricingPlanType.enterprise
    }
];

export function Pricing(props: { loggedIn: boolean }) {

    const [request] = useHttp<IAccountSetting>(false);
    const [currentPlan, setCurrentPlan] = useState<string | null>(null);
    const [showContactForm, setShowContactForm] = useState(false);

    const showForm = function () {
        setShowContactForm(true);
    }
    const hideForm = function () {
        setShowContactForm(false);
    }

    useEffect(() => {
        (async () => {
            const setting: IAccountSetting | null = props.loggedIn
                ? await request(`/account-settings?type=${AccountSettingType.pricingPlan}`)
                : null;
            const plan: string | null = setting?.keyValuePairs
                ?.find(s => s.key === AccountSettingType.pricingPlan)?.value ?? null;
            setCurrentPlan(plan);
        })();
    }, [request, props.loggedIn]);

    return <section className="section section--pricing">
        <div className="container-fluid pricing__content">
            {showContactForm && <BackButton click={hideForm}></BackButton>}
            <div className="row">
                <h3 className="pricing-title">Title Extraction packages</h3>
            </div>
            {!showContactForm && <Fragment>
                <div className="row">
                    {plans.map((plan: IPricingPlan) =>
                        <div key={plan.name} className="col-12 col-md-6 col-xxl-4">
                            <PricingPlan isCurrent={props.loggedIn && PricingPlanType[plan.type].toString() === currentPlan?.toLowerCase()}
                                model={plan}
                                onContact={plan.price > 0 ? undefined : showForm}
                                loggedIn={props.loggedIn}></PricingPlan>
                        </div>)}
                </div>
                <EnterprisePlanDesktop
                    isCurrent={currentPlan?.toLowerCase() === PricingPlanType[PricingPlanType.enterprise].toString()}></EnterprisePlanDesktop>
                <span className="response-time-text">
                    *Average support response time under one hour
                </span>
            </Fragment>}
            {showContactForm && <div className="pricing-form">
                <ScrollToTop />
                <ContactForm onSuccess={hideForm} formType={ContactFormType.pricing} center={true}></ContactForm>
            </div>}
        </div>
        {props.loggedIn && <div className="container-fluid cancel-subscription-wrapper">
            <CancelSubscription></CancelSubscription>
        </div>}
    </section>
}