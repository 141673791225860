import { NavLink, useNavigate, useParams } from "react-router-dom"
import { IResource } from "../../models/resource.model";
import resources from "../../resources-data";
import styles from "./ResourceDetail.module.scss";

export function ResourceDetail() {

    const { id } = useParams();
    const resource: IResource | undefined = resources.find((res: IResource) => res.id === id);
    const navigate = useNavigate();

    const goToPricingPage = function () {
        navigate('/pricing');
    }

    return <div>
        <div className={styles.breadcrumb}>
            <NavLink to={"/"}><span className={styles.bold}>Home</span></NavLink>
            <span className={`${styles.divider} material-icons`}>chevron_right</span>
            <NavLink to={"/resources"}><span>Resources</span></NavLink>
            <span className={`${styles.divider} material-icons`}>chevron_right</span>
            <span>{resource?.title}</span>
        </div>
        {!!resource && <div className={styles.video}>
            <iframe title="title extraction" width="100%" height="100%" src={resource.url}
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}></iframe>
        </div>}
        <p className={styles.title}>{resource?.title ?? ''}</p>
        {!!resource?.description && <p className={styles.content}>{resource?.description}</p>}
        {!resource?.description && <p className="text-center margin--top-large margin--bottom-large">Resource not found</p>}
        <div className="flex align-center margin--bottom-large">
            <button className="btn btn--black btn--with-icon" onClick={goToPricingPage}>
                <span>START FREE TRIAL</span>
            </button>
        </div>
    </div>
}