import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextField } from '@material-ui/core';
import { isValidEmail } from "../../helpers/common-helper";
import { useHttp } from "../../hooks/use-http";
import { UserLoginResult } from "../../models/enums/user-login-result.enum";
import { mainActions } from "../../store/main-slice";
import styles from "./LoginModal.module.scss";

export function LoginModal(props: { close: () => void }) {

    const navigate = useNavigate();
    const [request] = useHttp<string>(false);
    const [checkEmailRequest] = useHttp<UserLoginResult>(false);
    const [email, setEmail] = useState(null);
    const dispatch = useDispatch();

    const checkEmail = async function () {
        if (!isValidEmail(email)) {
            return;
        }
        const checkResult: UserLoginResult | null = await checkEmailRequest(`/accounts/check?email=${email}`);
        const options = {
            [UserLoginResult.subscriptionNotFound]: 'Subscription not found',
            [UserLoginResult.subscriptionExpired]: 'Subscription expired',
            [UserLoginResult.emailNotFound]: '',
            [UserLoginResult.incorrectPassword]: 'Incorrect email address or password',
            [UserLoginResult.emailNotVerified]: '',
            [UserLoginResult.locked]: 'Too many attempts to login. Your account has been locked for 30 minute. Please try again later',
            [UserLoginResult.pendingEmailVerification]: '',
        };
        const message: string = !!checkResult ? options[checkResult] : '';
        if (!!message) {
            dispatch(mainActions.setMessage({ text: message, isError: true }));
            return;
        }
        const registerPageStatuses: UserLoginResult[] = [
            UserLoginResult.emailNotVerified,
            UserLoginResult.emailNotFound
        ];
        const path: string = !!checkResult && registerPageStatuses.indexOf(checkResult) > -1
            ? 'register' 
            : checkResult === UserLoginResult.pendingEmailVerification
            ? 'pending'
            : checkResult === UserLoginResult.success 
            ? 'login' : '';

        if (!!path) {
            navigate({ pathname: path }, { state: email });
        }
        props.close();
    }

    const setupLoginButton = useCallback(function (): void {
        const { google } = window as any;
        const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;
        const elem = document.getElementById('g_id_signin');
        if (!google || !elem) {
            setTimeout(() => setupLoginButton(), 3000);
            return;
        }
        google.accounts.id.initialize({
            client_id: REACT_APP_GOOGLE_CLIENT_ID,
            callback: async (res: any) => {
                const accessToken: string | null = await request('/accounts', 'POST', JSON.stringify({ idToken: res.credential }));
                if (!!accessToken) {
                    navigate(`?#token=${accessToken}`, { replace: true });
                }
            }
        });
        google.accounts.id.renderButton(elem, { width: 240, shape: 'circle' });
    }, [navigate, request]);

    useEffect(() => {
        setupLoginButton();
    }, [setupLoginButton]);

    return <div className={styles.container}>
        <div className={styles.close}>
            <span className="material-icons material-icons--hidden">close</span>
            <h4 className="margin--center margin--bottom-tiny margin--top-tiny">Sign in</h4>
            <span className="material-icons" onClick={props.close}>close</span>
        </div>
        <div className={styles.content}>
            <div id="g_id_signin"></div>
            <div className={styles.divider}>OR</div>
            <TextField type="email" label="Email"
                autoFocus={true}
                onKeyDown={(e: any) => e.key === 'Enter' && checkEmail()}
                onKeyUp={(e: any) => setEmail(e.target.value)} />
            <button disabled={!isValidEmail(email)} onClick={checkEmail}
                className="btn btn--main" type="button">
                <span>Sign in</span>
            </button>
        </div>
    </div>
}