import { getToken } from "../helpers/local-storage-helper";

export const fetchApi = async function <TResult>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', body: any = null) {
    const { REACT_APP_API_URL } = process.env;
    const headers: any = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + getToken()
    }
    const response = await fetch(`${REACT_APP_API_URL}${path}`, {
        method: method,
        body: !!body ? JSON.stringify(body) : null,
        headers: headers
    });

    if (response.status === 404) {
        return null;
    }

    if (!response.ok) {
        const content:any = await response.json();
        throw new Error(content?.Title || (content as string));
    }

    if (method === 'DELETE') {
        return path.split('/').reverse()[0];
    }

    if (response.status === 204) {
        return null;
    }

    return await response.json() as TResult;
}

export const downloadFile = async (path: string, method: 'GET' | 'POST' = 'GET', body: any = null) => {
    const { REACT_APP_API_URL } = process.env;
    const headers = {
        'Authorization': 'bearer ' + getToken()
    }
    const response = await fetch(`${REACT_APP_API_URL}${path}`, {
        method: method,
        body: !!body ? JSON.stringify(body) : null,
        headers: headers
    });

    if (response.status === 404) {
        throw new Error('No data available for download.');
    }

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Download failed');
    }

    return await response.blob();
}
