import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHttp } from "../../hooks/use-http";
import { mainActions } from "../../store/main-slice";
import styles from "./Pending.module.scss";

export function Pending() {

    const [canShowButton, setCanShowButton] = useState<boolean>(true);
    const [request, status] = useHttp<string>(false);
    const location = useLocation();
    const dispatch = useDispatch();

    const sendAgain = async function() {
        const email: string | null = location.state as string;
        if (!email) {
            return;
        }
        setCanShowButton(false);
        await request(`/accounts/verify-email?email=${email}`, 'POST', null, null);
    }

    useEffect(() => {
        if (status === 200) {
            const text: string = 'Please check your email to confirm your email address';
            dispatch(mainActions.setMessage({ text: text, isError: false }));
        }
    }, [status, dispatch]);

    return <div className={styles.pending}>
        <div className={styles.title}>
            <h3>Please check your email to confirm your email address</h3>
        </div>
        <div>
            {canShowButton && <button onClick={sendAgain}
                className="text-link">Resend verification email
            </button>}
        </div>
    </div>
}