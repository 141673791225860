export interface IAccountSetting {
    accountId: string;
    keyValuePairs: IExtendedAccountSettingKeyValuePair[];
}

export interface IExtendedAccountSettingKeyValuePair {
    id: string;
    key: AccountSettingType;
    value: string;
}

export interface IAccountSettingCredits {
    titleDocumentLimit: number,
    renewalDate: string;
}

export enum AccountSettingType {
    titleDocumentUploadsLimit = 0,
    pricingPlan = 1
}