import { localStorageKeyOption } from "../models/enums/local-storage-key-option.enum";

const set = function(key: localStorageKeyOption, value: string): void {
    localStorage.setItem(key, value);
}
const get = function(key: localStorageKeyOption): string | null {
    return localStorage.getItem(key);
}
const remove = function (key: localStorageKeyOption): void {
    localStorage.removeItem(key);
}
const getPayloadValue = function(key: string): string | null {
    const token: string | null = getToken();
    if (!token) {
        return '';
    }
    return JSON.parse(atob(token.split('.')[1]))[key];
}

export const getToken = function(): string | null {
    const token: string | null = get(localStorageKeyOption.authToken);
    if (!token) return null;
    const expiry: number = +(get(localStorageKeyOption.authExpiry) || '0');
    if (Date.now() >= expiry) {
        return null;
    }
    return token;
}

export const isAuthenticated = () => !!getToken();

export const getEmail = function() {
    getPayloadValue('email');
}

export const isInRole = function(role: 'Enterprise' | 'User' | 'SuperAdmin'): boolean {
    return getPayloadValue('http://schemas.microsoft.com/ws/2008/06/identity/claims/role') === role;
}

export const setToken = function(token: string): void {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    set(localStorageKeyOption.authToken, token);
    set(localStorageKeyOption.authExpiry, (Date.now() + expiry * 1000).toString());
}

export const removeToken = function(): void {
    remove(localStorageKeyOption.authToken);
    remove(localStorageKeyOption.authExpiry);
}