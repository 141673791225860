import { Link } from "react-router-dom";
import logo from '../../assets/logo-white.svg';
import linkedIn from '../../assets/icn/linkedin.svg';
export function Footer() {
    return <footer>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="content-wrap flex align-middle align-justify">
                        <div className="flex flex-column">
                            <img className="logo" src={logo} alt="" />
                            <p className="font--md">All rights reserved to Click Reporting {new Date().getFullYear()}</p>
                        </div>
                        <div className="contact-wrapper">
                            <div className="contact">
                                <a href="mailto:info@clickreporting.ai">info@clickreporting.ai</a>
                                <a href="tel:0161 394 0690">0161 394 0690</a>
                                <a href="https://www.linkedin.com/company/click-reporting">Follow us on
                                    <img src={linkedIn} alt="" /></a>
                            </div>
                            <div className="flex terms-links">
                                <Link to="/terms">Terms of use</Link>
                                <Link to="/policy">Privacy policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}