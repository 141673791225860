import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField } from '@material-ui/core';
import { useHttp } from "../../hooks/use-http";
import { mainActions } from "../../store/main-slice";
import styles from "./Login.module.scss";

export function Login() {
    const navigate = useNavigate();
    const [request] = useHttp<string>(false);
    const [forgotPasswordRequest] = useHttp<string>(false);
    const [email, setEmail] = useState<string | null>(null)
    const [password, setPassword] = useState<string>('');
    const [forgotPasswordRequested, setForgotPasswordRequested] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const inputEmail: string | null = location.state as string;
        if (!!inputEmail) {
            setEmail(inputEmail);
        }
    }, [location]);

    const canSubmit = function () {
        return !!password;
    }

    const submit = async function () {
        const accessToken: string | null = await request('/accounts/login', 'POST',
            JSON.stringify({ email: email, password: password }));
        if (!!accessToken) {
            navigate(`?#token=${accessToken}`, { replace: true });
            return;
        }
        setPassword('');
    }

    const handleForgotPassword = async function () {
        if (!forgotPasswordRequested) {
            await forgotPasswordRequest('/accounts/forgot-password', 'POST', JSON.stringify({ email: email }), null);
            const text: string = 'If the email address was found in our system, we have sent an email to reset your password';
            dispatch(mainActions.setMessage({ text: text, isError: false }));
            setForgotPasswordRequested(true);
        }
    }

    return <div className={styles.login}>
        <div className={styles.title}>
            <h3>Enter Password</h3>
        </div>
        <div className={styles.content}>
            <TextField type="password" value={password} className="margin--bottom-medium"
                label="Password"
                autoFocus={true}
                onChange={(e: any) => setPassword(e.target.value)}
                onKeyDown={(e: any) => e.key === 'Enter' && submit()} />
            <button disabled={!canSubmit()} onClick={submit}
                className="btn btn--main" type="button">
                <span>Login</span>
            </button>
            <button className={`text-link ${styles.forgot}`} onClick={handleForgotPassword}>Forgot Password</button>
        </div>
    </div>
}