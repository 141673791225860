import { createAsyncThunk } from "@reduxjs/toolkit";
import { IEnterpriseAccountSettings } from "../../superadmin/models/enterprise-account-settings.model";
import { IEnterpriseUser } from "../../superadmin/models/enterprise-user.model";
import { IEnterprise } from "../../superadmin/models/enterprise.model";
import { downloadFile } from "../store-helper";
import { fetchApi } from "../store-helper";

export const fetchEnterpriseAccounts = createAsyncThunk(
    'enterprise/accounts/fetch',
    async () => await fetchApi('/enterprise-accounts') as IEnterprise[]
)
export const deleteEnterpriseAccount = createAsyncThunk(
    'enterprise/accounts/delete',
    async (account: IEnterprise) => 
    await fetchApi(`/enterprise-accounts/${account.id}`, 'DELETE') as string
)
export const downloadUploadDocumentHistory = createAsyncThunk(
    'enterprise/accounts/upload/document/history/download',
    async (account: IEnterprise, { rejectWithValue }) => {
        try {
            const data = await downloadFile(`/enterprise-account/${account.id}/upload-document-history/download`, 'POST');

            if (data instanceof Blob) {
                const url = window.URL.createObjectURL(data);
                const a = document.createElement('a');
                a.href = url;
                a.download = `EnterpriseHistory-${account.name}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }
        } catch (error: unknown) {
            if (error instanceof Error && error.message === 'No data available for download.') {
                alert('No data available for download.');
            } else if (error instanceof Error) {
                console.error('Download error:', error.message);
                return rejectWithValue(error.message);
            } else {
                console.error('An unknown error occurred.');
                return rejectWithValue('An unknown error occurred');
            }
        }
    }
);

export const createEnterpriseAccount = createAsyncThunk(
    'enterprise/accounts/create',
    async (account: IEnterprise) => 
    await fetchApi('/enterprise-accounts', 'POST', account) as IEnterprise
)
export const fetchEnterpriseAccountSettings = createAsyncThunk(
    'enterprise/account/settings',
    async (account: IEnterprise) => 
    await fetchApi(`/enterprise-account/${account.id}/settings`, 'GET') as IEnterpriseAccountSettings
)
export const saveEnterpriseAccountSettings = createAsyncThunk(
    'enterprise/accounts/settings/update',
    async (settings: IEnterpriseAccountSettings) => 
    await fetchApi(`/enterprise-account/${settings.accountId}/settings`, 'PUT', settings) as void
)
export const fetchEnterpriseUsers = createAsyncThunk(
    'enterprise/accounts/users/fetch',
    async (account: IEnterprise) => 
    await fetchApi(`/enterprise-account/${account.id}/users`) as IEnterpriseUser[]
)
export const deleteEnterpriseUser = createAsyncThunk(
    'enterprise/accounts/users/delete',
    async (user: IEnterpriseUser) => 
    await fetchApi(`/enterprise-account/${user.accountId}/users/${user.id}`, 'DELETE') as string
)
export const createEnterpriseUser = createAsyncThunk(
    'enterprise/accounts/users/create',
    async (user: IEnterpriseUser) => 
    await fetchApi(`/enterprise-account/${user.accountId}/users`, 'POST', user) as IEnterpriseUser
)