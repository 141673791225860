import { InnerBanner } from "../../components/inner-banner/InnerBanner";
import bannerImage from '../../assets/resources-banner.png';
import resource1 from '../../assets/resources/resource-1.png';
import resource2 from '../../assets/resources/resource-2.png';
import resource3 from '../../assets/resources/resource-3.png';
import styles from "./Resources.module.scss";
import resources from "../../resources-data";
import { IResource } from "../../models/resource.model";
import { useNavigate } from "react-router-dom";

export function Resources() {

    const resourceImages: string[] = [resource1, resource2, resource3];
    const navigate = useNavigate();
    const goToDetail = function (id: string) {
        navigate(`/resources/${id}`);
    }

    const goToPricingPage = function () {
        navigate('/pricing');
    }

    return <div>
        <InnerBanner
            title="Resources"
            subTitle="Discover more about automating data extraction from Title Reports"
            bannerImage={bannerImage}></InnerBanner>
        <div className={`${styles['box-wrapper']} row`}>
            {resources.map((resource: IResource, index: number) => <div className="col-6 col-md-4 margin--bottom-large">
                <div className={styles.box} onClick={() => goToDetail(resource.id)}>
                    <img src={resourceImages[index]} alt="" />
                    <p>{resource.title}</p>
                </div>
            </div>)}
        </div>
        <div className={`${styles['button-wrapper']} flex align-center`}>
            <button className="btn btn--black btn--with-icon" onClick={goToPricingPage}>
                <span>START FREE TRIAL</span>
            </button>
        </div>
    </div>
}