import { configureStore } from "@reduxjs/toolkit";
import enterpriseSlice from "./enterprise-slice";
import mainSlice from './main-slice';
const store = configureStore({
    reducer: {
        main: mainSlice,
        enterprise: enterpriseSlice
    }
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;