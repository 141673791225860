import { useReducer } from "react";

type actionTypes = 'INPUT' | 'BLUR' | 'RESET';

type IState = {
    value: string;
    isTouched: boolean
}
type IAction = {
    type: actionTypes;
    payload?: string;
}

const initialState: IState = {
    value: '',
    isTouched: false
}

const inputReducer = (state: IState, action: IAction) => {
    if (action.type === 'INPUT') {
        return { value: action.payload || '', isTouched: true };
    }
    if (action.type === 'BLUR') {
        return { value: state.value || '', isTouched: true };
    }
    if (action.type === 'RESET') {
        return { value: '', isTouched: false };
    }
    return state;
}

export function useInput(initialValue: string | undefined, validate: (input: string) => boolean) {
    const [inputState, dispatch] = useReducer(inputReducer, { ...initialState, value: initialValue || '' });
    const valueChangeHandler = (event: any) => dispatch({ type: 'INPUT', payload: event.target.value });
    const blurHandler = () => dispatch({ type: 'BLUR' });
    const resetHandler = () => dispatch({ type: 'RESET' });
    const isValid = validate(inputState.value);
    return {
        valueChangeHandler,
        blurHandler,
        resetHandler,
        isValid,
        hasError: inputState.isTouched && !isValid,
        value: inputState.value
    };
}